import AlertSlackOfError from "../../monitoring/AlertSlackOfError";

export const getShoppingCartData = (productsState, componentsState, itemsState, quantity, finalNotes) => {
  // const variantId = productsState?.activeObj.shopify.shopify_variant_id;

  // create object that will be submitted to cart
  let cartSubmissionObj = {
    items: [
      {
        // id: variantId, // NOTE: this is not needed here since we're generating a product at checkout time and will use that id to add-to-cart
        quantity,
        properties: {
          Brand: `${productsState?.activeObj.shopify.brand}`,
          Model: `${productsState?.activeObj.shopify.model}`,
          Year: `${productsState?.activeObj.shopify.year}`,
          ...(productsState?.activeObj.shopify.template ? { Template: `${productsState?.activeObj.shopify.template}` } : {}),
          ...(itemsState?.activeObjs["comfort-foam"]?._id == "yes-comfort-foam"
            ? { "Comfort Foam": `${itemsState.activeObjs["comfort-foam"].displayName}` }
            : {}),
          ...(itemsState?.activeObjs["double-side-wall"]?._id == "yes-double-side-wall"
            ? { "Double Side Wall": `${itemsState.activeObjs["double-side-wall"].displayName}` }
            : {}),
          ...(itemsState?.activeObjs["tall-seat-cover"]?._id == "yes-tall-seat-cover"
            ? { "Tall Seat Cover": `${itemsState.activeObjs["tall-seat-cover"].displayName}` }
            : {}),
          ...(productsState?.activeObj.shopify.strap ? { Strap: "Included" } : {}),
          ...(productsState?.activeObj.shopify["gas-cap"] ? { "Gas Cap": "Included" } : {}),
        },
      },
    ],
  };

  /**
   * add the 'properties' data to the obj so the full description of the product is present in the cart
   */

  // leave these out since we add them up above ^
  const componentsToSkip = ["comfort-foam", "double-side-wall", "tall-seat-cover"];

  componentsState.array.forEach((componentObj) => {
    try {
      if (componentsToSkip.includes(componentObj._id)) return;
      if (!componentObj.excluded) {
        let activeItem = itemsState.activeObjs[componentObj._id];

        let key = componentObj.displayName;
        let value = activeItem.displayName;

        // NOTE: this logic is not needed since we're only submitting 1 custom generated product to the cart
        // add the variant id to the cartSubmissionObj
        // if (activeItem?.shopify_variant_id && activeItem.price > 0) {
        //   cartSubmissionObj.items.push({
        //     id: activeItem?.shopify_variant_id,
        //     quantity,
        //   });
        // }

        // CUSTOM CODE: create visual seperator in cart
        // if (componentObj._id === "custom-patch-front") {
        //   cartSubmissionObj.items[0].properties["------------------------------------"] = "-";
        // }

        // hide custom patches if they are not set
        if (componentObj._id?.includes("custom-patch") && activeItem._id.includes("no-")) return;

        // make the value be the text_input if the customText items are active
        if (componentObj._id?.includes("custom-patch") && (activeItem._id === "customText-front-patch" || activeItem._id === "customText-side-patches")) {
          cartSubmissionObj.items[0].properties[key] = `"${activeItem.inputs.text_input}"`;
          // add additional font info
          cartSubmissionObj.items[0].properties[`${key} Font`] = activeItem.font_array[activeItem.inputs.active_font_index].displayName;
          return;
        }

        // update the cartSubmissionObj with the custom image
        if (componentObj._id?.includes("custom-patch") && activeItem._id.includes("customImage")) {
          if (activeItem.uploaded_logo_base64.length > 3) {
            cartSubmissionObj.items[0].properties[key] = "Custom Image";
            // this is used as temporary storage for the custom image so we don't have to find these items again
            cartSubmissionObj.items[0].properties["Custom Image URL"] = activeItem.uploaded_logo_base64;
            cartSubmissionObj.customPatchFront = componentObj._id;
            cartSubmissionObj.tempCustomUploadImageInputs = {
              _id: activeItem._id,
              inputs: { ...activeItem.inputs },
            };
          }
          // image has already been uploaded and is in the form of a src url
          else {
            cartSubmissionObj.items[0].properties["Custom Image URL"] = activeItem.uploaded_logo_src;
          }
          return;
        }

        // add gripper to the end of the value
        if (activeItem._id?.includes("gripper")) {
          cartSubmissionObj.items[0].properties[key] = `${value} Gripper`;
          return;
        }

        cartSubmissionObj.items[0].properties[key] = value;
      }
    } catch (error) {
      console.log("Error setting cart property:", componentObj, error);
      AlertSlackOfError("!!!!!!!!! getShoppingCartData !!!!!!!!!!!", `${componentObj._id} - ${error}}`);
    }
  });

  if (finalNotes.length > 0) {
    cartSubmissionObj.items[0].properties["Final Order Notes"] = finalNotes;
  }

  return [cartSubmissionObj];
};
