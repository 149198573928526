// import * as THREE from 'three';
import axios from "axios";
import { atom, useAtom } from "jotai";
import { isEqual } from "lodash";
import cloneDeep from "lodash.clonedeep";
import { traverse } from "object-traversal";
import { useEffect, useRef, useState } from "react";
import sift from "sift";
import toastr from "toastr";
import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";
import { pushGTMAnalyticsEvent } from "../../modules/pushGTMAnalyticsEvent";
import refreshExperience from "../../modules/refreshExperience";

// default data is init here
// will be overridden if data is being passed in from shopify
let shopifyData = require("./defaultShopifyData.json");

/**
 *
 * products
 *
 *
 */

// THIS IS THE GLOBAL products DATA OBJECT
export const products_state = atom({
  activeId: null,
  activeObj: null,
  array: null,
  isPrimed: false,
});

export const defaultProductId = () => {
  return window._tt?.shopifyData?.product?.tt_product_id || shopifyData.product.tt_product_id;
};

// function to update products_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_products_activeId = atom(null);

// holds the fetched product array
const all_products_array = atom(null);

// holds active accordion
export const accordion_state = atom(null);

/**
 *
 * components
 *
 */

// THIS IS THE GLOBAL components DATA OBJECT
export const components_state = atom({
  activeId: null,
  activeObj: null,
  array: null,
  isPrimed: false,
});

export const defaultComponentId = () => "top-material";

// function to update components_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_components_activeId = atom(null);

/**
 *
 * items
 *
 */

// THIS IS THE GLOBAL items DATA OBJECT
export const items_state = atom({
  activeIds: null,
  activeObjs: null,
  array: null,
  isPrimed: false,
});

// function to update items_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_items_activeIds = atom(null);

// holds the fetched non_items array
const non_items_array = atom(null);

/**
 *
 *
 * MISC
 *
 *
 */

export const is_experience_loaded_and_revealed = atom(false);

export const model_scale = atom(1);
export const camera_starting_position = atom([0, 0.85, -2]);
export const camera_lookAt = atom([0, 0.5, 0]);
export const model_starting_position = atom([0, 0.4, 0]);
export const model_starting_rotation = atom([-Math.PI, Math.PI * -0.3, 0]);
export const model_rotation_override = atom();

// Used when we want loading screen for assets not loading by LoadingManager
export const loading_state = atom(false);
export const loading_count = atom(0);
export const update_loading_count = atom(null, (get, set, _arg) => set(loading_count, get(loading_count) + _arg));

// used to store a base64 image of the canvas to send to shopping cart
export const canvas_base64 = atom(null);

// create unique session id for this runtime instance that can be used for us to identify errors
const { v4: uuidv4 } = require("uuid");
const sessionId = uuidv4();
export const session_id = atom(sessionId);

// used to store the dynamically created custom textures
const textureAtom_sidePatches = atom(null);
const textureAtom_frontPatch = atom(null);
export const customTextureAtomsObj = {
  textureAtom_sidePatches: textureAtom_sidePatches,
  textureAtom_frontPatch: textureAtom_frontPatch,
};

// data passed in from the UrlDataController that determines activeId's
export function GlobalDataManagers({
  // products
  products_activeId_fromURL,
  update_products_activeId_inURL,
  // components
  components_activeId_fromURL,
  update_components_activeId_inURL,
  // items
  items_activeIds_fromURL,
  update_items_activeIds_inURL,
  // components and items
  update_component_and_items_inURL,
}) {
  /**
   *
   * Load the data required to initialize the experience
   *
   */

  const [isInitDataLoaded, setIsInitDataLoaded] = useState(false);
  useEffect(() => {
    loadInitData();
  }, []);

  async function loadInitData() {
    // override default shopifyData if it's being passed in
    if (window._tt?.shopifyData) shopifyData = window._tt.shopifyData;

    await Promise.all([loadItemsListData(), loadItemTemplateData()]);

    await Promise.all([
      loadProductData(),
      loadComponentsData(),
      // loadSectionsData(),
      loadItemsData(),
      //// loadNonItemsData(),
    ]);

    compileSectionsAndItemsLists();

    setIsInitDataLoaded(true);
  }

  /**
   *
   * products
   *
   *
   */

  const [productsState, setProductsState] = useAtom(products_state);
  const [requested_products_activeId, reset_update_products_activeId] = useAtom(update_products_activeId);
  const [allProductsArray, setAllProductsArray] = useAtom(all_products_array);
  const allProductsArray_ref = useRef();

  // load products for experience to start
  async function loadProductData() {
    const res = await axios("/data/products.json");
    let allProducts = res.data;
    allProducts = updateProductDataFromShopifyData(allProducts);
    allProductsArray_ref.current = allProducts;
    setAllProductsArray(allProducts);
  }

  function updateProductDataFromShopifyData(allProductsArray) {
    // get matching product from array
    allProductsArray.forEach((product) => {
      product.shopify = { ...shopifyData.product };
    });

    return allProductsArray;
  }

  // when some child tells us to update products_state.activeId, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_products_activeId) {
      update_products_activeId_inURL(requested_products_activeId);
      reset_update_products_activeId(null); // reset this so it'll always register as an update
    }
  }, [requested_products_activeId]);

  // when URL tells us there is a new active product, update products_state
  useEffect(() => {
    if (!isInitDataLoaded || !products_activeId_fromURL) return;
    let productsArray = filterProductData();
    let activeProduct = productsArray.find((productObj) => {
      return productObj._id === products_activeId_fromURL;
    });
    let newProductsState = {
      activeId: products_activeId_fromURL,
      activeObj: activeProduct,
      array: productsArray,
      isPrimed: true,
    };
    setProductsState(newProductsState);
    console.log(`____ NEW productsState ______`, newProductsState);
    pushGTMAnalyticsEvent({
      event: "pageview",
      page: { path: `/virtualPageView/${newProductsState.activeId}`, title: `Virtual Title - ${newProductsState.activeObj.displayName}` },
    });
  }, [products_activeId_fromURL, isInitDataLoaded]);

  // filter and return an array with the active product
  function filterProductData() {
    let applicableProducts = allProductsArray.filter(sift({ _id: { $in: [products_activeId_fromURL] } }));
    return applicableProducts;
  }

  /**
   *
   * components
   *    also, sections are nested inside components
   *    also, items/items-list are nested inside components
   *
   */

  const [componentsState, setComponentsState] = useAtom(components_state);
  const [requested_components_activeId, reset_update_components_activeId] = useAtom(update_components_activeId);
  const allComponentsArray_ref = useRef();
  const allSectionsArray_ref = useRef();
  const itemsListArrays_ref = useRef();

  // load components for experience to start
  async function loadComponentsData() {
    let res = await axios("/data/components.json");
    let allComponents = res.data;
    allComponents = updateComponentDataFromShopifyData(allComponents); // CUSTOM CODE
    allComponentsArray_ref.current = allComponents;
  }
  async function loadSectionsData() {
    let res = await axios("/data/sections.json");
    let allSections = res.data;
    allSectionsArray_ref.current = allSections;
  }
  function updateComponentDataFromShopifyData(allComponentsArray) {
    let _allComponentsArray = allComponentsArray;
    /**
     * Exclusions
     */
    if (!shopifyData.product["comfort-foam"]) {
      _allComponentsArray = _allComponentsArray.map((componentObj) => {
        if (componentObj._id === "comfort-foam") {
          componentObj.excluded = true;
        }
        return componentObj;
      });
    }
    if (!shopifyData.product["tall-seat-cover"]) {
      _allComponentsArray = _allComponentsArray.map((componentObj) => {
        if (componentObj._id === "tall-seat-cover") {
          componentObj.excluded = true;
        }
        return componentObj;
      });
    }
    if (!shopifyData.product["double-side-wall"]) {
      _allComponentsArray = _allComponentsArray.map((componentObj) => {
        if (componentObj._id === "double-side-wall") {
          componentObj.excluded = true;
        }
        return componentObj;
      });
    }
    /**
     * Additions
     */
    if (shopifyData.product["omit-ribs"] === true) {
      _allComponentsArray = _allComponentsArray.map((componentObj) => {
        if (componentObj._id === "rib-type") {
          componentObj.excluded = true;
        }
        return componentObj;
      });
    }
    if (shopifyData.product["omit-front-patch"] === true) {
      _allComponentsArray = _allComponentsArray.map((componentObj) => {
        if (componentObj._id === "custom-patch-front") {
          componentObj.excluded = true;
        }
        return componentObj;
      });
    }
    return _allComponentsArray;
  }

  // inject correct sections objects and items-list id's into component objs
  function compileSectionsAndItemsLists() {
    let newComponentObj;
    let newComponentsArray = [];

    allComponentsArray_ref.current.forEach((componentObj) => {
      newComponentObj = { ...componentObj };

      // sections
      if (allSectionsArray_ref.current) {
        let sectionIds = newComponentObj.sections;
        sectionIds?.forEach((sectionId, index) => {
          newComponentObj.sections[index] = allSectionsArray_ref.current.find((sectionObj) => sectionObj._id === sectionId);
        });
      }

      // items-list
      let expandedItemsArray = [];
      newComponentObj.items.forEach((itemId, index) => {
        // expand any -items-list
        if (itemId?.includes("-items-list")) {
          let itemsArray_fromList = itemsListArrays_ref.current[itemId];
          // add the array of item id's from the items-list to the component.items
          expandedItemsArray = expandedItemsArray.concat(itemsArray_fromList);
        }
        // push the regular item id
        else {
          expandedItemsArray.push(itemId);
        }
      });

      // update component's .items
      newComponentObj.items = expandedItemsArray;

      // update component obj in originalArray
      newComponentsArray.push(newComponentObj);
    });

    allComponentsArray_ref.current = newComponentsArray;
  }

  // when some child tells us to update components_state.activeId, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_components_activeId && requested_components_activeId != components_activeId_fromURL) {
      update_components_activeId_inURL(requested_components_activeId);
      reset_update_components_activeId(null); // reset this so it'll always register as an update
    }
  }, [requested_components_activeId]);

  // when active product changes, we tell the URL to update the active component to be the product's first component
  // unless active component is already set in URL (i.e. shopper returning to a saved config)
  useEffect(() => {
    if (!productsState.activeId || components_activeId_fromURL) return;
    let activeProduct = productsState.array.find((productObj) => productObj._id === productsState.activeId);
    if (activeProduct.components[0]) update_components_activeId_inURL(activeProduct.components[0]);
  }, [productsState.activeId]);

  // when URL tells us there is a new active component, update components_state
  useEffect(() => {
    if (!components_activeId_fromURL || !productsState.isPrimed) return;
    let componentChoicesArray = filterComponentData(items_activeIds_fromURL);
    let activeComponent = componentChoicesArray.find((componentObj) => componentObj._id === components_activeId_fromURL);
    let newComponentsState = { activeId: components_activeId_fromURL, activeObj: activeComponent, array: componentChoicesArray, isPrimed: true };
    setComponentsState(newComponentsState);
    console.log(`____ NEW componentsState ______`, newComponentsState);
  }, [
    components_activeId_fromURL,
    productsState.isPrimed,
    items_activeIds_fromURL, // CUSTOM CODE: since component's have item dependencies we need to update components when items are updated
  ]);

  // filter and return an array with the list of components
  function filterComponentData(items_activeIds_fromURL) {
    let applicableComponentIds = productsState.array.find((productObj) => productObj._id === productsState.activeId).components;
    let applicableComponents = allComponentsArray_ref.current.filter(sift({ _id: { $in: applicableComponentIds } }));
    // make applicableComponents have same order as product.components array
    applicableComponents.sort((a, b) => {
      return applicableComponentIds.indexOf(a._id) - applicableComponentIds.indexOf(b._id);
    });

    // apply any dependencies
    if (items_activeIds_fromURL) {
      let activeItemIdsArray = getActiveItemIdsArray(items_activeIds_fromURL);
      applicableComponents.forEach((component) => {
        updateObjWithDependencies(component, activeItemIdsArray);
      });
    }

    return applicableComponents;
  }

  function getDefaultItemFromComponent(componentId) {
    let itemId = allComponentsArray_ref.current?.find((componentObj) => componentObj._id === componentId)?.items[0];
    let itemObj = allItemsArray_ref.current?.find((item) => item._id === itemId);
    return itemObj;
  }

  // CUSTOM CODE: handles the edge case where an old item id is being used and needs to be swapped for a new one
  function getReplacementItem(componentId, oldItemId) {
    try {
      let newItemId;
      switch (oldItemId) {
        case "black__lacing_color":
          newItemId = "black_full__lacing_color";
          break;

        default:
          newItemId = getDefaultItemFromComponent(componentId)?._id;
          break;
      }
      let itemObj = allItemsArray_ref.current?.find((item) => item._id === newItemId);
      return itemObj;
    } catch (error) {
      const _error = {
        title: "ERROR in getReplacementItem",
        oldItemId,
        componentId,
        error,
        components: JSON.stringify(productsState.activeObj?.components),
      };
      throw JSON.stringify(_error);
    }
  }

  /**
   *
   * items / non_items
   *
   */

  const [itemsState, setItemsState] = useAtom(items_state);
  const [requested_items_activeIds, reset_update_items_activeIds] = useAtom(update_items_activeIds);
  const allItemsArray_ref = useRef();
  const [allNonItemsArray, setAllNonItemsArray] = useAtom(non_items_array);
  const itemsTemplates_ref = useRef();

  function defaultItemActiveIds() {
    let activeIds = {};
    const activeProductDefaultItems = productsState.activeObj.defaultItems;
    componentsState.array.forEach((componentObj) => {
      if (componentObj?.items[0]?.includes("items-list")) {
        console.log("componentsState.array", componentsState.array);
        console.log("allComponentsArray_ref.current", allComponentsArray_ref.current);
        console.log("componentObj.items", componentObj.items);
        AlertSlackOfError("defaultItemActiveIds in GDM", `items-list is still active in componentObj ${componentObj}`);
        refreshExperience("GlobalDataManagers", sessionId, false, true);
      }
      if (activeProductDefaultItems && activeProductDefaultItems[componentObj._id])
        activeIds[componentObj._id] = { _id: activeProductDefaultItems[componentObj._id] };
      else activeIds[componentObj._id] = { _id: componentObj.items[0] };

      if (shopifyData.product["omit-ribs"] === true && componentObj._id === "rib-type") {
        activeIds[componentObj._id] = { _id: "no-ribs" };
      }
      if (shopifyData.product["omit-front-patch"] === true && componentObj._id === "custom-patch-front") {
        activeIds[componentObj._id] = { _id: "no-front-patch" };
      }
    });
    return activeIds;
  }

  // load items for experience to start
  async function loadItemsData() {
    let res = await axios("/data/items.json");
    let ttItems = res.data;
    let allItems = combineItemsAndApplyTemplates(ttItems, shopifyData.items);
    allItemsArray_ref.current = allItems;
  }
  // async function loadNonItemsData() {
  //   let res = await fetch('/data/non_items.json');
  //   let allNonItems = await res.json();
  //   setAllNonItemsArray(allNonItems);
  // }

  // load items-list for experience to start
  // CUSTOM CODE
  async function loadItemsListData() {
    itemsListArrays_ref.current = {};
    Object.entries(shopifyData.itemsLists).forEach(([listName, list]) => {
      itemsListArrays_ref.current[listName] = list;
    });
  }
  // CUSTOM CODE
  async function loadItemTemplateData() {
    let [color, gripper, pattern] = await Promise.all([
      axios("/data/item-templates/color.json").then((value) => value.data),
      axios("/data/item-templates/gripper.json").then((value) => value.data),
      axios("/data/item-templates/pattern.json").then((value) => value.data),
    ]);
    itemsTemplates_ref.current = {
      color,
      gripper,
      pattern,
    };
  }
  // CUSTOM CODE: use templates to expand items with default data
  function combineItemsAndApplyTemplates(ttItems, shopifyItems) {
    // apply prices from shopify to ttItems
    ttItems = ttItems.map((ttItem) => {
      let matchingShopifyItem = shopifyItems.find((shopifyItem) => shopifyItem._id === ttItem._id);
      if (!matchingShopifyItem) return ttItem;
      return {
        ...ttItem,
        ...matchingShopifyItem,
      };
    });
    // apply our tt templates to shopifyItems
    shopifyItems = shopifyItems
      .map((shopifyItem) => {
        if (ttItems.find((ttItem) => ttItem._id === shopifyItem._id)) return null; // check if it's already present in ttItems
        let template = determineTemplateFromItemId(shopifyItem._id);
        if (!template) return null; // if it's not a template item, return null
        return {
          ...template,
          ...shopifyItem,
          material_obj: {
            ...template.material_obj,
            properties: {
              ...template.material_obj.properties,
              map: shopifyItem.imageSrc,
            },
          },
        };
      })
      .filter((ttItemOrNull) => ttItemOrNull !== null); // removes nulls, which are items that are already in ttItems
    return [...ttItems, ...shopifyItems];
  }
  // CUSTOM CODE:
  function determineTemplateFromItemId(itemId) {
    if (itemId.includes("color-gripper")) return itemsTemplates_ref.current.gripper;
    else if (itemId.includes("color-stitching")) return itemsTemplates_ref.current.color;
    else if (itemId.includes("color-text")) return itemsTemplates_ref.current.color;
    else if (itemId.includes("-pattern")) return itemsTemplates_ref.current.pattern;
    else if (itemId.includes("-patch")) return itemsTemplates_ref.current.color;
    else if (itemId.includes("-logo")) return itemsTemplates_ref.current.color;
    else if (itemId === "hidden-rib") return itemsTemplates_ref.current.color;
  }

  // checking for out of stock items
  function updateItemDataFromShopifyData(allItems) {
    let outOfStockArray = window._tt?.outOfStockData || null;
    if (!outOfStockArray) return allItems;

    allItems.forEach((item) => {
      if (outOfStockArray?.includes(item._id)) item.outOfStock = true;
    });

    return allItems;
  }

  // when active product changes & components_state is primed, we tell the URL to update the activeIds to each component's default item
  // happens on site load unless activeIds is already set in URL (i.e. shopper returning to a saved config)
  useEffect(() => {
    if (!componentsState.isPrimed || items_activeIds_fromURL) return;
    update_items_activeIds_inURL(defaultItemActiveIds());
  }, [productsState.activeId, componentsState.isPrimed, componentsState]);

  // when some child tells us to update items_state.activeIds, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_items_activeIds && (items_activeIds_fromURL || defaultItemActiveIds())) {
      // CUSTOM CODE:
      // need to know what changed so we can change the active component id
      // _____________________________________________________________________
      let newActiveComponent = getObjectDiff(items_activeIds_fromURL || defaultItemActiveIds(), requested_items_activeIds)[0];
      if (newActiveComponent) {
        update_component_and_items_inURL(newActiveComponent, requested_items_activeIds);
        // _____________________________________________________________________
        // update_items_activeIds_inURL(requested_items_activeIds);
        reset_update_items_activeIds(null); // reset this so it'll always register as an update
      }
    }
  }, [requested_items_activeIds]);

  // when URL tells us there is a new items.activeIds, update items_state
  useEffect(() => {
    if (!items_activeIds_fromURL || !componentsState.isPrimed) return;
    let items_activeIds_clone = cloneDeep(items_activeIds_fromURL);
    items_activeIds_clone = checkForItemsLists(items_activeIds_clone); // CUSTOM CODE: handles sporadic error where some active id's were items-lists still
    // items_activeIds_clone = checkForOldComponentIds(items_activeIds_clone); // CUSTOM CODE: handles edge case where old id's are saved in URL
    items_activeIds_clone = addMissingComponents(items_activeIds_clone); // CUSTOM CODE: handles edge case where items activeIds from URL are missing some components
    let newItemsArray = getUpdatedItemsArray(items_activeIds_clone);
    let newActiveObjs = getActiveItemObjs(items_activeIds_clone, newItemsArray);
    let newItemsState = {
      activeIds: items_activeIds_clone,
      activeObjs: newActiveObjs,
      array: newItemsArray,
      isPrimed: true,
    };
    setItemsState(newItemsState);
    console.log(`____ NEW itemsState ______`, newItemsState);
  }, [items_activeIds_fromURL, componentsState.isPrimed]);

  function checkForItemsLists(activeIds) {
    let newActiveIds = { ...activeIds };
    Object.keys(activeIds).forEach((componentId) => {
      if (activeIds[componentId]?._id?.includes("-items-list")) {
        let itemsArray_fromList = itemsListArrays_ref.current[activeIds[componentId]._id];
        newActiveIds[componentId]._id = productsState.activeObj.defaultItems[componentId] || itemsArray_fromList[0];
      }
    });
    return newActiveIds;
  }

  // CUSTOM code
  // function checkForOldComponentIds(activeIds) {
  //   let newActiveIds = {...activeIds};
  //   Object.keys(activeIds).forEach((componentId) => {
  //     if (componentId == 'thumb_text__firstBase') {
  //       newActiveIds['thumb_customText__firstBase'] = {"_id": "no_customText_thumb"};
  //       newActiveIds['embroidery_customText_color'] = {"_id": "black__embroidery_color"};
  //       delete newActiveIds['thumb_text__firstBase'];
  //     }
  //     if (componentId == 'thumb_customText__singleWeltFielders') {
  //       newActiveIds['thumb_customText'] = newActiveIds['thumb_customText__singleWeltFielders'];
  //       delete newActiveIds['thumb_customText__singleWeltFielders'];
  //     }
  //     if (componentId == 'thumb_customText__firstBase') {
  //       newActiveIds['thumb_customText'] = newActiveIds['thumb_customText__firstBase'];
  //       delete newActiveIds['thumb_customText__firstBase'];
  //     }
  //   })
  //   return newActiveIds;
  // }

  function addMissingComponents(activeIds) {
    let newActiveIds = { ...defaultItemActiveIds(), ...activeIds };
    return newActiveIds;
  }

  function getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));

    return diff;
  }

  function getActiveItemObjs(activeIds, itemsArray) {
    const unavailableItems = [];
    let activeObjs = { ...activeIds };
    let mods = {};

    const updateObjViaPaths = (objToUpdate, mods) => {
      for (var path in mods) {
        var k = objToUpdate;
        var steps = path.split(".");
        steps.pop(); // removing the _id entry so we replace whole object
        var last = steps.pop();
        steps.forEach((e) => (k[e] = k[e] || {}) && (k = k[e]));
        k[last] = mods[path];
        // if (!mods[path]?._id || !k[last]?._id) {
        //   console.log('path', path)
        // console.log('mods[path]', mods[path])
        // console.log('k[last]', k[last])
        // AlertSlackOfError('getActiveItemObjs in GDM', `Blank active item obj: ${path} | ${JSON.stringify(activeIds)}}`)
        // refreshExperience("GlobalDataManagers", sessionId, false, true);
        // }
      }
      return objToUpdate;
    };

    traverse(activeObjs, (context) => {
      let { key, value, meta } = context;
      // if we've found an abbreviated item obj
      if (key === "_id") {
        // add its path and the full item obj to mods
        mods[meta.currentPath] = { ...itemsArray.find((item) => item._id === value) };
        // check for edge case where old item id's are saved in URL
        const isEmpty = Object.keys(mods[meta.currentPath]).length === 0;
        if (isEmpty) {
          // AlertSlackOfError('getActiveItemObjs in GDM', `Blank active item obj: ${meta.currentPath} = ${value} being replaced properly`)
          mods[meta.currentPath] = getReplacementItem(meta.currentPath.split(".")[0], value);
          unavailableItems.push({
            componentId: meta.currentPath.split(".")[0],
            unavailableItemId: value,
            newItemId: mods[meta.currentPath]?._id,
          });
        }
      }
      // else if (key === "patch-material-rear" && Object.keys(value).length === 0) {
      //   activeIds[meta.currentPath] = { _id: "white-color-logo" };
      //   const defaultRearMaterial = shopifyData.items.find((item) => item._id === "white-color-logo")
      //   activeObjs[meta.currentPath] = defaultRearMaterial;
      // } else {
      //   console.info("***** NO KEY FOUND ******", key);
      // }
    });

    if (unavailableItems.length > 0) {
      // alert user of missing items
      unavailableItemAlerts([...new Set(unavailableItems.map((item) => item.unavailableItemId))]);
      // update active ids in URL
      let newActiveIds = { ...activeIds };
      unavailableItems.forEach((obj) => {
        newActiveIds[obj.componentId]._id = obj.newItemId;
      });
      update_items_activeIds_inURL(newActiveIds);
    }

    activeObjs = updateObjViaPaths(activeObjs, mods);
    return activeObjs;
  }

  function unavailableItemAlerts(unavailableItems) {
    unavailableItems?.forEach((item) => {
      const title = item
        .replace(/color/g, "")
        .replace(/(pattern).*$/g, "$1") // remove anything after "pattern"
        .replace(/-/g, " ")
        .replace(/\s+/g, " "); // remove any double spaces
      toastr.info(`"${title}" is unavailable`);
    });
  }

  // traverses activeIds obj and finds any inputs
  // uses those inputs to update item in itemsArray
  // also applies updates from dependencies
  function getUpdatedItemsArray(activeIds) {
    let itemsArrayCopy;
    // prime the itemsState.array if not already present
    if (!itemsState.array) itemsArrayCopy = getApplicableItemsArray();
    // use copy of itemsState.array so any existing inputs or edits are copied
    else itemsArrayCopy = itemsState.array;

    // update items from inputs
    traverse(activeIds, (context) => {
      const { parent, key, value } = context;
      if (key === "inputs" && value) {
        let item = itemsArrayCopy.find((obj) => obj._id === parent._id);
        updateItemWithInputs(item, value);
      }
    });

    // update items from dependencies
    let activeItemIdsArray = getActiveItemIdsArray(activeIds);
    itemsArrayCopy.forEach((item) => {
      updateObjWithDependencies(item, activeItemIdsArray);
    });

    return itemsArrayCopy;
  }

  function getApplicableItemsArray() {
    // make array of all applicable item id's
    let itemIdArray = [];
    componentsState.array.forEach((component) => {
      itemIdArray = itemIdArray.concat(component.items);
    });
    // filter the allItemsArray to only include the applicable ones according to itemIdArray
    let filteredItemsArray = allItemsArray_ref.current.filter(sift({ _id: { $in: itemIdArray } }));

    // if there are non_items, add those to the items array
    if (allNonItemsArray) filteredItemsArray = filteredItemsArray.concat(allNonItemsArray);

    return filteredItemsArray;
  }

  // makes array of activeId's for ease-of-use in updateObjWithDependencies
  function getActiveItemIdsArray(activeItemIds) {
    let activeIdArray = [];
    traverse(activeItemIds, (context) => {
      const { parent, key, value } = context;
      if (key === "_id") activeIdArray.push(value);
    });
    return activeIdArray;
  }

  // updates the item specified with the new inputs
  function updateItemWithInputs(item, newInputObj) {
    // pasting the newInputObj to item.inputs
    item.inputs = newInputObj;
    // traverse new inputs
    Object.entries(newInputObj).forEach(([inputKey, inputValue]) => {
      // traverse item
      traverse(item, (context) => {
        const { parent, key, value, meta } = context;
        // update item values with input values
        if (inputKey == key && !meta.currentPath?.includes("inputs") && inputValue !== null) {
          parent[key] = inputValue;
        }
      });
    });
  }

  function updateObjWithDependencies(obj, activeIdArray) {
    if (obj?.dependencies && obj?.dependencies.length > 0) {
      // iterate the dependency objects
      obj.dependencies.forEach((dependencyObj) => {
        // setup this variable so we can keep track if 1 of the item values are active and subsequent item values that are inactive won't effect the update value
        let isOneItemValueActive = false;
        // check if an active obj._id is in this dependency object's .itemValues
        dependencyObj.itemValues.forEach((objId) => {
          if (activeIdArray?.includes(objId)) {
            isOneItemValueActive = true;
          }
        });
        // apply the dependency updates to the obj
        if (isOneItemValueActive) {
          Object.entries(dependencyObj.updates).forEach(([keyToUpdate, newValue]) => {
            obj[keyToUpdate] = newValue;
          });
        }
        // CUSTOM CODE: since we're only dealing with the excluded value (bool) we will make opposite updates if the itemValues are not active
        else {
          Object.entries(dependencyObj.updates).forEach(([keyToUpdate, newValue]) => {
            obj[keyToUpdate] = !newValue;
          });
        }
      });
    }
  }

  return null;
}
